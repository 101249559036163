<template>
  <div class="gua-page">
    <div class="tabs-container">
      <div class="tabs-box">
        <van-tabs v-model="active" sticky scrollspy @change="loadData">
          <van-tab :title="item.title" v-for="(item, index) in list" :key="index"></van-tab>
        </van-tabs>
      </div>
      <div class="filter-btn-box">
        <div class="filter-btn" @click="visible = !visible">
          <img src="~@/assets/images/icon/icon_filter.svg" class="icon-filter" alt="">
        </div>
        <img src="~@/assets/images/icon/filter_shadow.png" class="shadow" alt="">
      </div>
    </div>
    <div class="gua-container">
      <div class="gua-main">
        <div class="gua-card">
          <div class="gua-title van-hairline--bottom">产品包修规定</div>
          <div class="gua-content">
            <div class="gua-content-item" :class="{ 'van-hairline--top': index > 0 }" v-for="(item,index) in content.list" :key="index">
              <div class="se-title">{{ item.title }}</div>
              <div class="se-content" v-html="item.content">
              </div>
              <div class="tips" v-if="item.tips">{{ item.tips }}</div>
            </div>
          </div>
        </div>
        <div class="gua-card">
          <van-collapse v-model="coll1">
            <van-collapse-item title="不属于包修范围" name="1">
              <div class="collapse-content">
                <p v-html="content.content1"></p>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="gua-card" v-if="!hidden">
          <van-collapse v-model="coll2">
            <van-collapse-item title="退换货总则（根据国家新三包、新消法）" name="1">
              <div class="collapse-content">
                <p v-html="content.content2"></p>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <transition name="van-fade">
      <div class="gua-mask" v-if="visible" @click="visible=false"></div>
    </transition>
    <transition name="show">
      <div class="filter-container" v-if="visible">
        <div class="filter-content">
          <div class="filter-item flex-center"
            :class="{ 'active' : active === index }"
            v-for="(item, index) in list" :key="index"
            @click="changeTab(index)">
            {{ item.title }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { Tabs, Tab, Collapse, CollapseItem, Toast } from 'vant'
  import { getGuaranteeInfo, getCategoryList } from '@/api/common'

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem
    },
    data() {
      return {
        active: 0,
        visible: false,
        coll1: [],
        coll2: [],
        list: [],
        content: {},
        hidden: true
      }
    },
    created() {
      this.loadCategory()
    },
    methods: {
      loadCategory() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getCategoryList({is_bx: 1}).then(res => {
          this.list = res.data.list
          toast.clear()
          this.$nextTick(() => {
            this.loadData()
          })
        })
      },
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getGuaranteeInfo({id: this.list[this.active].id}).then(res => {
          const { list, content1, content2, is_th } = res.data
          this.content = {
            list,
            content1,
            content2
          }
          this.hidden = is_th == 1 ? false : true
          this.coll1 = []
          this.coll2 = []
          toast.clear()
        })
      },
      changeTab(index) {
        this.active = index
        this.visible = false
        this.$nextTick(() => {
          this.loadData()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>
<style lang="less">
  .gua-page {
    .van-tabs__line {
      background-color: #186AFF;
      box-shadow: 0 0.2667vw 0.2667vw rgba(24,106,255,.1) 
    }
  }
  .tabs-box {
    .van-tabs__line {
      width: 5.8667vw;
    }
    .van-tab {
      font-size: 3.7333vw;
      font-weight: 500;
      color: #9C9DAA;
      padding: 0 4.6667vw !important;
    }
    .van-tab--active {
      color: #1C1F20;
      font-size: 4.2667vw;
    }
  }
  .gua-card {
    .van-cell {
      height: 12vw;
      align-items: center
    }
    .van-cell__title {
      font-weight: bold;
      font-size: 4vw;
      color: #1C1F20;
    }
    .van-collapse-item__content {
      padding: 4vw
    }
  }
  .se-content {
    line-height: 2;
    p {
      font-size: 3.4667vw;
      color: #4C4C57;
      letter-spacing: 0;
      line-height: 2;
      &+p {
        margin-top: 2.2667vw;
      }
    }
  }
</style>